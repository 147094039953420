import React from "react";

import * as styles from "./Workshop.module.scss";

export default ({ index, data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles.index}>[PW{index}]</span>{" "}
        <span className={styles.title}>{data.title}</span>
      </div>
      <div className={styles.event}>{data.event}</div>
      <div className={styles.location}>{data.location}</div>
      <div className={styles.date}>{data.date}</div>
      {data.url && (
        <a href={data.url} className={styles.slides}>
          [Slides]
        </a>
      )}
    </div>
  );
};
